.school-info-tag {
  border-radius: 5rem;
  padding: 1.4rem 2.6rem;
  @include flex-c;
  text-decoration: none;
  gap: 0.8rem;
  .icon {
    @include flex-c;
  }
  &.blue {
    background-color: var(--asg-blauw);
    color: var(--white);
  }
  &.green {
    background-color: var(--asg-groen);
    color: var(--white);
  }
  &.grey {
    background-color: #d4d4d4;
    color: var(--asg-blauw);
  }
  svg {
    @include box(2rem);
  }
}
