.table {
  overflow-x: auto;
  scrollbar-width: thin;
  max-width: 100%;
  background:
    linear-gradient(to right, white 30%, rgba(255, 255, 255, 0)),
    linear-gradient(to right, rgba(255, 255, 255, 0), white 70%) 0 100%,
    radial-gradient(farthest-side at 0% 50%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
    radial-gradient(farthest-side at 100% 50%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
  background-repeat: no-repeat;
  background-color: white;
  background-size:
    40px 100%,
    40px 100%,
    14px 100%,
    14px 100%;
  background-position:
    0 0,
    100%,
    0 0,
    100%;
  background-attachment: local, local, scroll, scroll;
  border-radius: 0.2rem 0.2rem 0 0;
  &:focus {
    outline: 0.2em solid rgb(0, 86, 112, 0.1);
  }
  table {
    width: 100%;
    display: table;
    border-spacing: 0;
    border-collapse: separate;
    box-sizing: border-box;
    text-indent: 0;
  }

  caption {
    text-align: start;
    background-color: var(--section-background-color, inherit);
    padding-block: 0.8rem;
    font-size: 1.5rem;
    line-height: 1.6;
    @include media-breakpoint-up($bpUp) {
      text-align: center;
    }
  }

  th,
  td {
    min-width: 14rem;
  }

  // Helper classes that can be put on td or th elements.
  .option-inline-text-end {
    text-align: end;
  }
  .option-inline-text-start {
    text-align: start;
  }
  .option-inline-text-center {
    text-align: center;
  }
  .option-block-text-end {
    vertical-align: bottom;
  }
  .option-block-text-start {
    vertical-align: top;
  }

  thead {
    background-color: var(--asg-paars);
    tr {
      th {
        color: white;
        text-align: start;
        padding: 0.8rem 2.4rem;
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 0.8rem 2.4rem;
        border-bottom: 1px solid rgba(0, 86, 112, 1);
      }

      &:nth-child(odd) {
        td.has-accent {
          background-color: rgba(var(--asg-paars-rgb), 0.2);
        }
      }
      &:nth-child(even) {
        td.has-accent {
          background-color: rgba(var(--asg-paars-rgb), 0.4);
        }
      }
    }
  }
}
