.vacancy-result {
  display: grid;
  grid-template-areas: "image" "content";
  grid-template-columns: minmax(0, 1fr);
  @include media-breakpoint-up($bpUp) {
    grid-template-areas: "content image";
    grid-template-columns: 4fr 3fr;
  }
  &-wrapper {
    position: relative;
  }
  &-content {
    background-color: var(--white);
    padding: 3.2rem 2.4rem;
    border-radius: 2.5rem;
    grid-area: content;
    margin: 0;
    z-index: 1;
    margin: -5rem 0 0 0;
    @include media-breakpoint-up($bpUp) {
      margin: 0 -5rem 0 0;
      padding: 4.4rem 4rem 3.3rem;
    }
  }
  &-title {
    @extend h3;
    margin: 0 0 1rem;
    color: var(--first-color);
  }
  &-tags {
    margin: 1rem 0 0;
    .button,
    .school-info-tag {
      padding: 1.1rem 1.8rem !important;
      font-size: 1.6rem !important;
    }
    .button-group {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      justify-content: center;
      @include media-breakpoint-up($bpUp) {
        justify-content: flex-start;
      }
    }
  }
  &-image {
    grid-area: image;
    border-radius: 2.5rem 2.5rem 0 0;
    overflow: hidden;
    @include media-breakpoint-up($bpUp) {
      border-radius: 0 2.5rem 2.5rem 0;
    }
    img {
      @include box(100%);
      object-fit: cover;
    }
  }
  &.vertical {
    grid-template-areas: "content";
    grid-template-columns: minmax(0, 1fr);
    .vacancy-result {
      &-content {
        text-align: center;
        padding: 2.3rem 1.3rem 3.2rem;
        width: 100%;
        margin: 0;
      }
      &-tags {
        justify-content: center;
        margin: 1.5rem 0 0;
      }
    }
  }
}
