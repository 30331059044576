.menu {
  @include media-breakpoint-up($bpUp) {
    gap: 2.3rem;
  }
  @include media-breakpoint-up(xl) {
    gap: 1.5rem;
  }
  @media screen and (min-width: 1350px) {
    gap: 3.3rem;
  }
  &-item {
    position: relative;
    &-link {
      text-decoration: none;
      color: white;
      font-size: 3.4rem;
      position: relative;
      display: block;
      font-weight: 500;
      @include media-breakpoint-up($bpUpNav) {
        font-size: 1.8rem;
        padding: 0 0 4rem;
        margin: 0 0 -4rem;
        font-weight: 400;
      }
      @include media-breakpoint-down($bpDownNav) {
        padding: 3.4rem 0 0 7rem;

        &:before {
          content: "";
          width: 4rem;
          height: calc(100% - 3.4rem);
          background-position: center;
          left: 0;
          opacity: 0.2;
          background-repeat: no-repeat;
          position: absolute;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='39' height='27' viewBox='0 0 39 27' fill='none'%3E%3Cpath d='M38.4442 12.4851C38.3734 11.2253 38.1045 9.99387 37.6232 8.8332C36.0379 4.78499 32.5134 1.81253 28.2529 0.934952C27.7433 0.83587 27.2196 0.793407 26.71 0.708479C26.6393 0.694325 26.5685 0.68017 26.4977 0.666016L-26.4545 0.666016C-26.6668 0.694325 -26.8791 0.736788 -27.1056 0.765098C-32.0172 1.33128 -36.1787 4.67176 -37.7781 9.34276C-38.1037 10.3053 -38.316 11.2961 -38.4151 12.3011C-38.4434 12.4426 -38.4717 12.5842 -38.5 12.7115V14.3252C-38.4575 14.4667 -38.4292 14.5941 -38.4009 14.7356C-37.8206 20.7655 -33.1071 25.578 -27.0773 26.2575C-26.596 26.3141 -26.1148 26.3282 -25.6335 26.3282C-8.52065 26.3282 8.57805 26.3282 25.6768 26.3282C31.3952 26.3424 36.4201 22.5631 38.0054 17.0711C38.2602 16.236 38.4159 15.3868 38.4583 14.5092C38.4725 14.4242 38.4866 14.3393 38.5008 14.2685V12.6974C38.4725 12.6266 38.4583 12.5558 38.4442 12.4851ZM-25.7326 17.7647C-28.0964 17.7081 -29.9648 15.7548 -29.9082 13.391C-29.8516 11.0272 -27.8982 9.15875 -25.5344 9.21537C-23.1989 9.27199 -21.3447 11.197 -21.3588 13.5325C-21.3871 15.8963 -23.3122 17.793 -25.676 17.7647C-25.7043 17.7647 -25.7184 17.7647 -25.7326 17.7647ZM0.000396729 17.7647C-2.36341 17.7647 -4.27428 15.8397 -4.26013 13.4759C-4.26013 11.1121 -2.33511 9.20121 0.0287056 9.21537C2.39251 9.21537 4.30338 11.1404 4.28922 13.5042C4.27507 15.8539 2.36421 17.7647 0.000396729 17.7647ZM25.6909 17.7647C23.3271 17.7789 21.4021 15.8822 21.3879 13.5184C21.3738 11.1545 23.2705 9.22952 25.6343 9.21537C27.9981 9.20121 29.9231 11.0979 29.9373 13.4617C29.9373 13.4759 29.9373 13.4759 29.9373 13.49C29.9373 15.8397 28.0406 17.7506 25.6909 17.7647Z' fill='white'/%3E%3C/svg%3E");
        }
      }
      &--active {
        @include media-breakpoint-down($bpDownNav) {
          svg {
            transform: rotate(180deg);
          }
        }
      }
    }
    &:first-of-type {
      @include media-breakpoint-down($bpDownNav) {
        .menu-item-link {
          padding: 0 0 0 7rem;
          &::before {
            height: 100%;
          }
        }
      }
    }
    &.active .menu-item-link::before {
      opacity: 1;
    }
    //.menu-item-buttons
    &-buttons {
      display: flex;
      gap: 2.4rem;
      flex-wrap: wrap;
      padding: 3.4rem 0 0 7rem;
      @include media-breakpoint-up($bpUpNav) {
        padding: 0;
      }
      @include media-breakpoint-up(xl) {
        gap: 1.5rem;
      }
      @media screen and (min-width: 1350px) {
        gap: 2.4rem;
      }
      a {
        transition:
          background-color 0.3s,
          color 0.3s;
        text-decoration: none;
        padding: 0.85rem 2.4rem;
        border: solid 1px var(--white);
        border-radius: 3rem;
      }
      .menu-button {
        display: block;
        color: var(--white);
        &:hover {
          background-color: var(--white);
          color: var(--copy-color);
        }
      }
      .search-link {
        background-color: var(--white);
        color: var(--copy-color);
        gap: 0.8rem;
        @include flex-c;
        svg {
          @include box(1.5rem);
        }
        &:hover {
          background-color: transparent;
          color: var(--white);
        }
      }
    }
    svg {
      @include box(2.4rem);
      @include transition;
      margin-left: 3rem;
      @include media-breakpoint-up($bpUpNav) {
        @include box(1rem);
        margin-left: 1rem;
      }
    }
    &-mobile-text {
      padding: 4rem 0 0 7rem;
      font-size: 1.8rem;
      color: var(--white);
      max-width: 40rem;
      @include media-breakpoint-up($bpUpNav) {
        display: none;
      }
    }
    &--dropdown {
      &-list {
        overflow: hidden;
        width: max-content;
        ul {
          padding: 3.4rem 0 0;
          @include media-breakpoint-up($bpUpNav) {
            padding: 1.2rem 0;
          }
        }
      }
      &-wrapper {
        @include transition();
        display: grid;
        grid-template-rows: 0fr;
        border-radius: 3rem;
        @include media-breakpoint-up($bpUpNav) {
          background: var(--theme-gradient);
        }
        @include media-breakpoint-down($bpDownNav) {
          padding: 0 0 0 7rem;
        }
      }
      &-item {
        &-link {
          text-decoration: none;
          color: var(--white);
          display: block;
          padding: 0.4rem 0;
          white-space: nowrap;
          font-size: 1.8rem;
          @include media-breakpoint-up($bpUpNav) {
            padding: 0.4rem 2.4rem;
          }
        }
      }

      @include media-breakpoint-up($bpUpNav) {
        position: relative;
        &-item {
          &--first {
            display: none;
          }
        }

        &-wrapper {
          position: absolute;
          transform: translate(-25%, 100%);
          bottom: -3rem;
          left: 0;
          @include transition;
        }

        ul {
          display: flex;
          flex-direction: column;
        }
      }
      @include media-breakpoint-down($bpDownNav) {
        &-wrapper--active {
          grid-template-rows: 1fr;
        }
      }
    }
    &:hover {
      .menu-item--dropdown-wrapper {
        @include media-breakpoint-up($bpUpNav) {
          grid-template-rows: 1fr;
        }
      }
      svg {
        transform: rotate(180deg);
      }
    }
  }
}
