:root {
  // Fixed.
  // Fredoka is variable font!
  --font-family-primary: "Fredoka", sans-serif;

  // Defaults.
  --black: black;
  --white: white;
  --lightgrey: lightgrey;
  --darkgrey: #3c3c3c;
  --basicgrey: #e9e9e9;

  // Colors.
  --asg-paars: #662783;
  --asg-paars-rgb: 102, 39, 131;
  --asg-blauw: #1065af;
  --asg-groen: #1c8c37;
  --asg-rood: #cc0234;
  --asg-lichtgrijs: #eeeeee;
  --asg-grijs: #b0b0b0;
  --asg-donkerblauw: #18277a;

  // Overrideable stuff below;
  --theme-card-background: var(--first-color);
  --default-theme-gradient: var(
    --custom-theme-gradient,
    var(--first-color, #1065af) 35.75%,
    var(--second-color, #662783) 87.52%
  );

  --theme-gradient: linear-gradient(49deg, var(--default-theme-gradient));
  --agenda-item-gradient: linear-gradient(180deg, var(--default-theme-gradient));
  // Because this can be different per project. Or not?
  --text-clip-gradient: var(--theme-gradient);
  --section-theme-gradient: linear-gradient(90deg, var(--default-theme-gradient));

  --theme-checklist-fill: var(--custom-theme-checklist-fill, var(--asg-groen, rgba(24, 39, 122, 0.8)));

  // The arrow on article results.
  --theme-article-result-arrow: var(--asg-groen);

  // Text.
  --copy-color: var(--darkgrey);
  --vertical-flow: 2rem;
}

// Transition
$transition: (
  ease: "ease-in-out",
  speed: 0.2s
);

/*  Padding */
$padding: (
  small: 1rem,
  medium: 3rem,
  large: 5rem,
  column-mobile: 15px,
  column-desktop: 15px
);
$minColumnHeight: 4rem;

// @see space.scss
$spaces-mobile: (
  s: 2.5rem,
  m: 5rem,
  l: 10rem,
  xl: 12.5rem
);
$spaces-desktop: (
  s: map-get($spaces-mobile, s),
  m: map-get($spaces-mobile, m),
  l: map-get($spaces-mobile, l),
  xl: 15rem
);

$sectionPadding: 5rem;
$smallFactor: 0.5;
$largeFactor: 2;

$max-sections: 20;

$mobileFactor: 0.5;

/* Breakpoints */
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1250px,
  // xxl: 1400px,
  // xxxl: 1600px,
  // xxxxl: 1800px,
  // hd: 1920px,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,
);
$bpDown: sm;
$bpUp: md;
$bpDownNav: lg;
$bpUpNav: xl;

/* Swiper */
$swiper-theme-color: black;
$swiper-preloader-color: black;
$swiper-navigation-color: white;
$swiper-pagination-color: white;
$swiper-navigation-size: 44px;
$paginationColorName: "black";

// Z-index mapping;
// @see: styles/helpers/0-mixins.scss
$z-index: (
  modal: 200,
  navigation: 120,
  header_overflow: 116,
  header: 115,
  map: 110,
  footer: 100
);
