.contact-section {
  background: var(--section-background-color);
  &.section-background-gradient {
    .contact-section-quote-text {
      color: var(--white);
    }
    .contact-section-branding img {
      filter: grayscale(1) brightness(5);
    }
  }
  &-quote {
    position: relative;
    width: 100%;
    background-color: var(--asg-lichtgrijs);
    border-radius: 2.5rem;
    align-items: center;
    overflow: hidden;
    display: grid;
    grid-template-areas: "image" "quote";
    grid-template-columns: 1fr;
    grid-template-rows: 18rem auto;
    @include media-breakpoint-up($bpUp) {
      grid-template-areas: "quote image";
      grid-template-columns: 5fr 3fr;
      grid-template-rows: auto;
    }
    &-wrapper {
      position: relative;
      display: flex;
      align-items: center;
      flex-direction: column;
      @include media-breakpoint-up($bpUp) {
        flex-direction: row;
        margin: 0 calc(100% / 12) 2rem 0;
      }
    }
    &-title {
      grid-area: quote;
      padding: 1rem 4rem 6rem;
      color: var(--first-color);
      @include media-breakpoint-up($bpUp) {
        padding: 5rem;
      }
    }
    &-text {
      font-size: 2rem;
      margin: 0 calc(100% / 12) 0 0;
      @include media-breakpoint-up($bpUp) {
        font-size: 2.2rem;
      }
    }
    &-image {
      height: 100%;
      grid-area: image;
      overflow: hidden;
      position: relative;
      border-radius: 0 0 50% 50%;
      @include media-breakpoint-up($bpUp) {
        margin-left: -5rem;
        border-radius: 0;
      }
      img {
        position: absolute;
        left: 0;
        bottom: -5rem;
        right: 0;
        object-fit: cover;
        width: 100%;
        @include media-breakpoint-up($bpUp) {
          border-radius: 50% 0 0 50%;
          top: -5rem;
          bottom: -5rem;
          right: -2.5rem;
          height: calc(100% + 10rem);
        }
      }
    }
    &-contact {
      background-color: var(--asg-groen);
      padding: 2rem 2.4rem;
      border-radius: 2.5rem;
      color: var(--white);
      margin: auto 0;
      right: 0;
      max-width: 25rem;
      transform: translate(0, -4rem);
      display: flex;
      gap: 0.8rem;
      align-items: flex-start;
      @include media-breakpoint-up($bpUp) {
        position: absolute;
        transform: translateX(50%);
      }
      .contact-icon {
        margin: 0.5rem 0 0;
        svg {
          @include box(2rem);
        }
      }
      a {
        display: block;
        color: var(--white);
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
  &-branding {
    display: flex;
    gap: 5rem;
    flex-direction: column;
    align-items: center;
    padding: 5rem 0 0;
  }
}
