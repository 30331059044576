.contact-card {
  background: var(--theme-gradient);
  color: var(--white);
  padding: 2.6rem 4rem;
  border-radius: 3.5rem;
  &-title {
    @extend h3;
    margin: 0 0 3rem;
  }
  &-info a {
    color: var(--white);
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}
