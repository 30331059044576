$footer_breakpoint_down: md;
$footer_breakpoint: lg;
.footer {
  background: var(--theme-gradient);
}

.footer.footer-has-border-image {
  position: relative;
  margin: 5vw 0 0;
}

.footer-border-image {
  aspect-ratio: 200 / 9;
  position: absolute;
  left: 0;
  top: 1px;
  transform: translateY(-100%);
}

.footer-inner {
  position: relative;
  padding: 5rem;
}

.footer-first {
  display: grid;
  grid-template-areas: "logo" "texts" "socials" "copyright";
  row-gap: 3rem;

  @include media-breakpoint-up($footer_breakpoint) {
    grid-template-areas: "logo . texts socials" "copyright copyright . .";
    grid-template-columns: 13.8rem 10rem 1fr auto;
    column-gap: 3rem;
    row-gap: 0;
  }
}

// Link always to asg.nl/scholen.
.footer-logo {
  grid-area: logo;
  display: flex;
  width: 13.8rem;
  justify-self: center;
  &:not(.theme-0) {
    img {
      height: 5rem;
      width: auto;
    }
  }
  img {
    object-fit: contain;
  }
  @include media-breakpoint-up($footer_breakpoint) {
    width: 100%;
  }
}
.socials {
  grid-area: socials;
  display: flex;
  gap: 1.6rem;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  @include media-breakpoint-up($footer_breakpoint) {
    justify-content: flex-end;
    align-items: flex-start;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .footer-social-icon {
    border-radius: 0.8rem;
    width: 3.6rem;
    height: 3.6rem;
    background-color: var(--white);
    fill: var(--asg-donkerblauw);
    @include transition(background-color);
    &:hover {
      background-color: var(--asg-lichtgrijs);
    }
  }
}

.footer-texts {
  grid-area: texts;
  justify-self: center;

  gap: 3rem;
  color: white;

  display: grid;
  grid-template-columns: 1fr;
  @include media-breakpoint-up($footer_breakpoint) {
    grid-template-columns: 1fr 1fr;
    justify-items: center;
  }

  .footer-text {
    h3 {
      @extend .h4 !optional;
      margin-block-end: 1.8rem;
    }
    .text {
      font-size: 1.4rem;
      line-height: calc(18 / 14);
    }
  }
  body[data-style-theme="corporate"] & {
    @include media-breakpoint-down($footer_breakpoint_down) {
      .footer-text {
        text-align: center;
      }
    }
    .footer-address {
      .text {
        p {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}
.copyright {
  grid-area: copyright;
  letter-spacing: 0.16rem;
  text-transform: uppercase;
  color: rgba(151, 149, 181, 1);

  body[data-style-theme="corporate"] & {
    @include media-breakpoint-down($footer_breakpoint_down) {
      text-align: center;
    }
  }
}

body[data-style-theme="school-straight"],
body[data-style-theme="school-circle"],
body[data-style-theme="school-wave"] {
  .footer-first {
    position: relative;
    grid-template-areas:
      "texts"
      "logo"
      "socials";

    @include media-breakpoint-up($footer_breakpoint) {
      grid-template-areas: "logo texts socials";
      grid-template-columns: min-content auto max-content;
    }
  }
  .footer-inner {
    overflow: hidden;
    padding: 4rem;
    @include media-breakpoint-up($footer_breakpoint) {
      padding: 2.3rem 4rem;
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 20rem;
      height: 100%;
      background: var(--white);
      opacity: 0.2;
    }
  }
  .footer-texts {
    grid-template-columns: 1fr;
  }
  .footer-address {
    @include flex-c;
    .text p {
      text-align: center;
      font-size: 1.6rem;
      text-transform: uppercase;
      span:not(:last-child) {
        &:after {
          content: "|";
          margin: 0 0.5rem;
        }
      }
    }
  }
  .socials {
    @include flex-c;
  }
}

body[data-style-theme="school-straight"] {
  .footer-inner::before {
    top: 1rem;
    clip-path: polygon(calc(100% - 5rem) 0, 100% 100%, 100% 100%, 0 100%, 0 0);
  }
}

body[data-style-theme="school-wave"] {
  .footer-inner::before {
    clip-path: url(#section_wave_top);
    width: 100%;
    height: 50%;
    bottom: 0;
    top: unset;
    @include media-breakpoint-up($footer_breakpoint) {
      clip-path: url(#footer-clippath-wave);
      width: 20vw;
      height: calc(100% + 1rem);
      bottom: -1rem;
    }
  }
}

body[data-style-theme="school-circle"] {
  .footer-inner::before {
    @include media-breakpoint-down($footer_breakpoint_down) {
      clip-path: url(#section_rounded_top);
      width: 100%;
      bottom: 0;
      top: unset;
      aspect-ratio: 62 / 5;
      height: unset;
    }
    @include media-breakpoint-up($footer_breakpoint) {
      clip-path: url(#footer-clippath-circle);
    }
  }
}
