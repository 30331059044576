.job-alert-section {
    .job-alert-content {
        display: grid;
    }

    // See customFields in includes/job_alert/.
    .dynamic-columns {
        column-count: 2;
        @include media-breakpoint-up($bpUp) {
            column-count: 3;
        }
    }
}
