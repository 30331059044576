.share-button {
  position: relative;
  .share-links {
    position: absolute;
    opacity: 0;
    background-color: var(--white);
    border-radius: 3.5rem;
    padding: 3rem 4.5rem;
    width: max-content;
    z-index: 1;
    left: 0;
    margin: 0;
    max-width: calc(100vw - 3rem);
    @include media-breakpoint-up(sm) {
      transform: translateX(-33%);
    }
    .share-links-title {
      width: min-content;
      flex: 0 0 100%;
      color: #525252;
    }
    .share-link-item span {
      display: none;
    }
    &.active {
      opacity: 1;
    }
  }
}
